<template>
  <div class="about">
    <head-nav></head-nav>
    <about-img></about-img>
    <div class="about-box">
      <h3 class="about-box-title">
        <img
          :src="$t('about.title-4')"
          alt=""
          :style="this.$i18n.locale==='en'?'width:220px;height:auto;':''"
        >
      </h3>

      <div class="about-box-content">
        <p v-html="$t('about.about-development.list-1.content')">
        </p>
      </div>

      <div class="about-box-imgs">
        <img
          :src="$t('about.about-development.list-1.img')"
          alt=""
        >
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: #f8f8f8;

  .about-box {
    margin: 0 30px;
    padding-bottom: 90px;

    &-title {
      margin: 50px 0 30px 0;
      text-align: center;

      img {
        height: 70px;
      }
    }

    &-content {
      font-size: 28px;
      line-height: 40px;
    }

    &-imgs {
      width: 100%;
      margin-top: 40px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
